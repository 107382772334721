<template>
  <v-app id="inspire">
    <v-app-bar
      app
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="white"
      dense
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="mr-12 align-center">
        <span class="title">Chaika</span>
      </v-toolbar-title>
      <v-spacer />
      <div>
        {{ currentUser.fullName }}
      </div>
      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>Выйти</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer" app fixed width="300"
      :clipped="$vuetify.breakpoint.lgAndUp"
    >
      <sidebar></sidebar>
    </v-navigation-drawer>

    <v-main class="d-flex">
      <v-container fluid class="d-flex" style="height: 100%; width: 100%; max-width: 100%;">
        <router-view :key="$route.path" class="animated" style="height: 100%; width: 100%; align-content: flex-start"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import { Sidebar } from 'components/layout/'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    components: {
      Sidebar,
    },
    data: () => ({
      drawer: null
    }),
    computed: {
      ...mapGetters(['currentUser', 'isAuthenticated'])
    },
    watch: {
      isAuthenticated (val) {
        if (!val) {
          this.$router.replace({ name: 'Login' })
        }
      }
    },
    methods: {
      ...mapActions(['logout'])
    }
  }
</script>
